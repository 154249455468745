:root {
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

.calculator-container {
  width: 80%;
  margin: 2rem auto;
  font-family: var(--main-font);
}

* {
  margin: 0;
  padding: 0;
}

.section-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.col_1 {
  /* border: 1px solid lightgray;
  background-color: red; */
  width: 45%;
}

.col_2 {
  /* border: 1px solid lightgray;
  background-color: green; */
  width: 45%;
}

.trip-summary {
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.trip-summary-number {
  font-weight: 600;
  color: #fff;
}

#results-column {
  border-radius: 5px;
  background-color: #1675aa;
  height: 400px;
  padding:10px 15px;
}

#results-column-mobile {
  display:none;
}

.desktop{
  display:block;
}

.mobile{
  display: none;
}

.driverpay-input{
  width: 182px !important;
}


.span-mobile{
  display:none;
}

@media only screen and (max-width: 1000px) {
  .calculator-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .section-group{
    display:block;
  }
  .calculator-container {
    width: 90%;
  }
  #results-column {
    height: 500px;
    display:none;
  }

  #results-column-mobile {
    display:block;
    padding:10px 20px;
  }
  .col_1{
    width:100%;
    margin-bottom: 2rem;
  }
  .col_2{
    width:100%;
  }

  .trip-summary {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .mobile-row-col{
    flex-direction: column !important;
  }

  .mobile-row-col > div{
    margin-bottom: 10px;
  }

  .desktop{
    display:none;
  }
  .mobile{
    display:block;
  }
  .span-mobile{
    display:inline-block;
  }

  .cal-mobile-btns{
    flex-wrap: wrap;
  }

  .cal-mobile-btns button{
    width:45% !important;
    margin-bottom: 10px;
  }

}
