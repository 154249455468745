:root {
  --main-font: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Ubuntu, Arial, sans-serif;
}

.referral-button {
  /* background-color: #03a9f4; */
  background-color: #dddddd;
  color: black;
  /* letter-spacing: 2px; */
  outline: none;
  border: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: var(--main-font);
  margin-right: 10px;
}

.referral-button:hover {
  background-color: #0298db;
  color: white;
  cursor: pointer;
}
