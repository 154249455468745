.calcButton {
  width: 10rem;
  height: 2rem;
  margin-right: 2rem;
  background: #93cddd;
  cursor: pointer;
}

.calcButtonsWrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.inputEmail {
  color: black;
}

.inputEmail::placeholder {
  color: black;
}

.textareaSendEmail {
  width: 40%;
  padding: 8px 10px;
  margin: 0.5rem 0;
  display: inline-block;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: var(--main-font);
  border: 1px solid #ccc;
}

.textareaSendEmail::placeholder {
  color: black;
}

.sectionGroup {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.col_1 {
  width: 45%;
}

.col_2 {
  width: 45%;
}

.truckdeskIcon {
  width: 300px;
}

#column_1_textarea {
  width: 30%;
}

.mobile{
  display:none;
}

@media only screen and (max-width: 600px) {
  .truckdeskIcon {
    width: 120px;
  }

  #column_1_textarea {
    width: 100%;
  }

  .col_1 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .col_2 {
    width: 100%;
    display: flex;
    margin-top: 20px;
  }

  .sectionGroup{
    flex-direction: column;
  }

  .calcButton {
    margin-bottom: 0.5rem;
  }



}
