@media only screen and (max-width: 1000px) {
  .login-container {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .login-container {
    width: 90%;
  }
}

.login-button {
  margin-top: 1rem;
}
